import React, { FC, useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { IArticle } from '../api/articles'
import { fetchTranslation } from '../api/articles/articles.query'
import { ILanguage } from '../api/languages'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { LanguagesContext } from '../context/languages.context'
import { LanguageSelectorComponent } from './LanguageSelectorComponent'

export interface IArticleHeaderProps {
  article: IArticle
  onLanguageChange: (language: ILanguage) => void
}

export const ArticleHeader: FC<IArticleHeaderProps> = ({
  article,
  onLanguageChange: onLanguageChangeProp,
}) => {
  const [title, setTitle] = useState(article.Title)
  const { user } = useContext(UserContext)
  const { languages } = useContext(LanguagesContext)
  const onLanguageChange = async (language: ILanguage) => {
    onLanguageChangeProp(language)
    if (language.Code === article.TargetLanguage || language.Code === '') {
      setTitle(article.Title)
      return
    }

    try {
      const { Title } = await fetchTranslation({
        ContentId: article.ArticleId,
        LanguageCode: language.Code,
      })
      setTitle(Title)
    } catch (error) {
      console.error(error)
      setTitle(article.Title)
    }
  }

  useEffect(() => {
    setTitle(article.Title)
  }, [user.PreferredLanguage, article])

  return (
    <View style={styles.titleFavoriteContainer}>
      <Text style={styles.tileTitle} numberOfLines={3}>
        {title}
      </Text>
      <View
        style={[
          styles.iconWrapper,
          !languages.length ? { paddingLeft: 15 } : {},
        ]}
      >
        <LanguageSelectorComponent
          sourceLanguage={article.SourceLanguage}
          defaultValue={article.TargetLanguage}
          onChange={(language) => onLanguageChange(language)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleFavoriteContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tileTitle: {
    fontFamily: 'Roboto_500Medium',
    color: Colors.oxfordBlue,
    fontSize: 17,
    marginBottom: 17,
    lineHeight: 20.5,
    paddingBottom: 1,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
})
