import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { differenceBy } from 'lodash'
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  RefObject,
} from 'react'
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  ViewToken,
} from 'react-native'
import { IArticle } from '../api/articles'
import { ITag } from '../api/tags'
import { RefreshControl } from '../components/RefreshControl'
import { Tile } from '../components/Tile'
import Colors from '../constants/Colors'
import { refreshDelayTime } from '../constants/Config'
import { ArticlesContext, ArticlesProvider } from '../context'
import { PreferredTagsContext } from '../context/preferred-tags.context'
import { ScreenSizeContext } from '../context/screen-size.context'
import { ScrollToTopContext } from '../context/scroll-to-top.context'
import { IMessageCache, UnreadContext } from '../context/unread.context'
import { RootStackParamList, ArticleTabParamList } from '../types'
import { now } from '../utils'
import { View } from './Themed'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.listWhite,
  },
  flatList: {
    backgroundColor: Colors.listWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty: { fontWeight: 'bold', marginVertical: 20, color: 'gray' },
})

export interface IListComponentProps {
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  ArticleIds?: Array<string>
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<ArticleTabParamList, 'ArticleList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
}

export enum ArticleTypes {
  Video,
  Facebook,
  LinkedIn,
  Twitter,
  Instagram,
  TikTok,
  URL,
}

export const getArticleType = (item: IArticle) => {
  if (item.VideoUrl) {
    return ArticleTypes.Video
  } else if (item.FacebookArticleUrl) {
    return ArticleTypes.Facebook
  } else if (item.LinkedInArticleUrl) {
    return ArticleTypes.LinkedIn
  } else if (item.TwitterArticleUrl) {
    return ArticleTypes.Twitter
  } else if (item.InstagramArticleUrl) {
    return ArticleTypes.Instagram
  } else if (item.TikTokArticleUrl) {
    return ArticleTypes.TikTok
  }
  return ArticleTypes.URL
}

export const onScroll =
  (
    isScrolled: boolean,
    setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>,
    isScrollToTop?: boolean,
    setIsScrollToTop?: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (isScrollToTop) {
      if (
        isScrollToTop &&
        !isScrolled &&
        event.nativeEvent.contentOffset.y === 0 &&
        setIsScrollToTop
      ) {
        setIsScrollToTop(false)
      }
    } else {
      if (event.nativeEvent.contentOffset.y === 0 && isScrolled) {
        setIsScrolled(false)
      } else if (event.nativeEvent.contentOffset.y > 0 && !isScrolled) {
        setIsScrolled(true)
      }
    }
  }

export const ListComponent: React.FC<IListComponentProps> = ({
  ContentTag,
  SourceTag,
  SearchText,
  ArticleIds,
}) => {
  const tags = useContext(PreferredTagsContext)
  const ScrollToTopC = useContext(ScrollToTopContext)
  const { unreadItems, setUnreadItems } = useContext(UnreadContext)
  const [CreatedBefore, setCreatedBefore] = useState<string>()
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isScrollToTop, setIsScrollToTop] = useState<boolean>(false)
  const flatlistRef = useRef<FlatList>(null)
  const viewabilityConfig = {
    itemVisiblePercentThreshold: 75,
  }

  const onViewableItemsChanged = (info: {
    changed: ViewToken[]
    viewableItems: ViewToken[]
  }) => {
    setIsScrollToTop((prevScroll: boolean) => {
      if (!prevScroll)
        setUnreadItems((prevUnread: IMessageCache) => {
          if (prevUnread.unreadArticles.length) {
            const changed = info.changed
              .filter(({ key, isViewable }) => {
                return (
                  key && isViewable && prevUnread.unreadArticles.includes(key)
                )
              })
              .map(({ key }) => key)
            return {
              ...prevUnread,
              unreadArticles: prevUnread.unreadArticles.filter(
                (key) => !changed.includes(key)
              ),
            }
          }
          return prevUnread
        })
      return prevScroll
    })
  }

  let viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged },
  ])

  const prevTagsRef = useRef<ITag[]>(tags)

  useEffect(() => {
    const comparator = (tag: ITag) => tag.Title
    const diff1 = differenceBy(tags, prevTagsRef.current, comparator)
    const diff2 = differenceBy(prevTagsRef.current, tags, comparator)
    if (!diff1.length && !diff2.length) return

    prevTagsRef.current = tags
  }, [tags])

  useEffect(() => {
    setCreatedBefore(now().toISOString())
  }, [prevTagsRef.current])

  useEffect(() => {
    if (
      ScrollToTopC.ScrollToTop &&
      flatlistRef.current?.props.data?.length &&
      isScrolled &&
      !isScrollToTop
    ) {
      setIsScrollToTop(true)
      setIsScrolled(false)
      flatlistRef.current?.scrollToIndex({ index: 0 })
    }
  }, [ScrollToTopC])

  return (
    <ArticlesProvider
      ContentTag={ContentTag}
      SourceTag={SourceTag}
      SearchText={SearchText}
      ArticleIds={ArticleIds}
      CreatedBefore={CreatedBefore}
    >
      <InnerListComponent
        onScroll={onScroll(
          isScrolled,
          setIsScrolled,
          isScrollToTop,
          setIsScrollToTop
        )}
        isScrolled={isScrolled}
        flatlistRef={flatlistRef}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs}
        setCreatedBefore={setCreatedBefore}
      />
    </ArticlesProvider>
  )
}

interface IInnerListComponentProps {
  isScrolled: boolean
  flatlistRef: RefObject<FlatList<any>>
  viewabilityConfigCallbackPairs: MutableRefObject<any>
  onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  setCreatedBefore: (date: string) => void
}

function InnerListComponent({
  isScrolled,
  flatlistRef,
  viewabilityConfigCallbackPairs,
  onScroll,
  setCreatedBefore,
}: IInnerListComponentProps) {
  const { articles, isLoading, total, setCount, setIsRefreshable } =
    useContext(ArticlesContext)
  const { height } = useContext(ScreenSizeContext)
  const [isRefreshing, setRefreshing] = useState<boolean>(false)
  const { unreadItems, refresh } = useContext(UnreadContext)

  useEffect(() => {
    setIsRefreshable(!isScrolled)
  }, [isScrolled])

  useEffect(() => {
    if (isRefreshing) refresh()
  }, [isRefreshing])

  return (
    <FlatList
      ref={flatlistRef}
      contentContainerStyle={styles.flatList}
      style={[styles.containerView, { height }]}
      data={articles}
      keyExtractor={(article) => `${article.ArticleId}`}
      onEndReached={() => {
        if (!articles.length || total <= articles.length) return

        setCount(articles.length)
      }}
      onEndReachedThreshold={0.5}
      onScroll={onScroll}
      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      refreshControl={
        <RefreshControl
          refreshing={(isRefreshing || isLoading) && !isScrolled}
          size="large"
          tintColor={Colors.orangeWeb}
          onRefresh={() => {
            setRefreshing(true)
            setTimeout(() => {
              setCreatedBefore(now().toISOString())
              setRefreshing(false)
            }, refreshDelayTime)
          }}
        />
      }
      renderItem={({ item }) => {
        return (
          <>
            <Tile
              article={item}
              articleType={getArticleType(item)}
              isUnread={unreadItems.unreadArticles.includes(item.ArticleId)}
            />
            {/** This line used to be "total === articles.indexOf(item) + 1" */}
            {articles.length === articles.indexOf(item) + 1 && (
              <View
                style={{
                  backgroundColor: Colors.platinum,
                  marginBottom: 75,
                }}
              />
            )}
          </>
        )
      }}
      ListEmptyComponent={
        isLoading ? null : (
          <Text style={styles.empty}>
            Nincs a keresési feltételeknek megfelelő cikk
          </Text>
        )
      }
    />
  )
}
