import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Icon } from 'react-native-elements'
import { ILanguage, defaultLanguage } from '../api/languages'
import Colors from '../constants/Colors'
import { apiUrl, httpRegexp } from '../constants/Config'
import { LanguagesContext } from '../context/languages.context'
import { path } from '../utils'
import LanguageDropdownComponent from './LanguageDropdownComponent'
const LINE_HEIGHT = 33
const MAX_ITEM_SIZE = 5

export const getImageUri = (uri: string): string => {
  uri = uri || ''
  if (uri.startsWith('data:image')) return uri
  return httpRegexp.test(uri) ? uri : path.join([apiUrl, uri])
}

export interface ILanguageSelectorProps {
  sourceLanguage: string
  defaultValue: string
  onChange: (value: ILanguage) => void
}

const renderRightIcon = (visible: boolean) => {
  const currentFlag = visible ? (
    <Icon name={'expand-less'} size={30} style={{ marginRight: '4px' }} />
  ) : (
    <Icon name={'expand-more'} size={30} style={{ marginRight: '4px' }} />
  )
  return currentFlag
}

const renderItem = (uri: string) => {
  return (
    <View style={styles.dropdownContainerTextWrap}>
      <Image
        source={{ uri: uri === defaultLanguage.Flag ? uri : getImageUri(uri) }}
        resizeMode={'cover'}
        style={styles.icon}
      />
    </View>
  )
}
const renderListItem = (uri: string, code: string, sourceLanguage: string) => {
  const isSourceLanguage = code === sourceLanguage
  return (
    <View style={styles.dropdownContainerTextWrap}>
      <Image
        source={{
          uri: uri === defaultLanguage.Flag ? uri : getImageUri(uri),
        }}
        resizeMode={'cover'}
        style={[styles.icon, { marginRight: '8px' }]}
      />
      {!!code ? (
        <Text
          //@ts-ignore
          style={{
            borderWidth: isSourceLanguage && '1px',
            borderColor: isSourceLanguage && 'black',
            padding: '2px',
            color: 'black',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {code}
        </Text>
      ) : null}
    </View>
  )
}

export const LanguageSelectorComponent: FunctionComponent<
  ILanguageSelectorProps
> = ({ sourceLanguage, defaultValue, onChange }) => {
  // Contexts
  const { languages } = useContext(LanguagesContext)

  // Value State InitValue
  const initialLanguage =
    languages.find((language) => language.Code === defaultValue) ||
    defaultLanguage

  // States
  const [value, setValue] = useState<ILanguage>(initialLanguage)
  const [visible, setVisible] = useState<boolean>(false)

  // Refs
  const ref = useRef<ILanguage>(value)

  const maxHeight = Math.min(
    ((languages?.length || 0) + 1) * LINE_HEIGHT,
    MAX_ITEM_SIZE * LINE_HEIGHT
  )
  const getFlag = useCallback(() => {
    if (value === defaultLanguage) {
      const targetLanguage = languages.find(
        (language) => language.Code === defaultValue
      )
      return targetLanguage?.Flag || defaultLanguage.Flag
    }
    return value.Flag
  }, [value, defaultLanguage, defaultValue, languages])

  useEffect(() => {
    const prev = ref.current
    const next = value
    ref.current = value
    if (!prev || prev === next) return

    onChange(value)
  }, [value])

  if (!languages.length) return null
  return (
    <View style={styles.viewContainer}>
      <LanguageDropdownComponent
        visible={visible}
        setVisible={setVisible}
        sourceLanguage={sourceLanguage}
        data={[defaultLanguage, ...languages]}
        mode="default"
        dropdownPosition="bottom"
        placeholder="Original"
        labelField="Code"
        valueField="Code"
        value={value}
        disable={!languages.length}
        onChange={(value) => setValue(value)}
        renderRightIcon={() => renderRightIcon(visible)}
        renderLeftIcon={() => renderItem(getFlag())}
        renderItem={(item) =>
          renderListItem(item.Flag, item.Code, sourceLanguage)
        }
        style={styles.dropdown}
        maxHeight={maxHeight}
        minHeight={LINE_HEIGHT}
        showsVerticalScrollIndicator={true}
        fontFamily="Roboto_400Regular"
        placeholderStyle={styles.placeholder}
        selectedTextStyle={styles.placeholder}
        selectedTextProps={{ numberOfLines: 1 }}
        containerStyle={styles.dropdownContainer}
        scrollable={true}
        autoScroll={true}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    width: 70,
    marginRight: 13,
  },
  placeholder: {
    display: 'none',
  },
  viewContainer: {
    width: 70,
    borderColor: Colors.darkPlatinum,
    borderWidth: 1,
    borderRadius: 3,
    marginLeft: 13,
  },
  dropdownContainer: {
    width: 80,
    borderColor: Colors.darkPlatinum,
    borderWidth: 1,
    borderRadius: 3,
  },
  dropdownContainerScroll: {
    width: 70,
    borderColor: Colors.darkPlatinum,
    borderWidth: 1,
    borderRadius: 3,
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
  dropdownContainerTextWrap: {
    flex: 1,
    flexDirection: 'row',
    height: 25,
    alignItems: 'center',
    margin: 8,
  },
  dropdownContainerText: {
    color: 'red',
    fontSize: 14,
  },
  icon: {
    marginRight: '8px',
    width: '20px',
    height: '15px',
  },
})
