import React, { useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { Text, View, StyleSheet, Pressable, Linking } from 'react-native'
import { Card } from 'react-native-elements'

import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SentimentIcon } from './SentimentIcon'
import { IArticle } from '../api/articles'
import { DateConverter } from './DateConverter'
import { path } from '../utils'
import { apiUrl, httpRegexp } from '../constants/Config'

import { ArticleTypes, getArticleType } from './ListComponent'
import { ArticleSourceIconComponent } from './ArticleSourceIconComponent'
import { ArticleHeader } from './ArticleHeader'
import Browser from '../constants/Browser'
import { ScreenSizeContext } from '../context/screen-size.context'
import { UserContext } from '../context'

export interface ITileProps {
  article: IArticle
  articleType: ArticleTypes
  isUnread: boolean
}

export const getImageUri = (uri: string): string => {
  uri = uri || ''
  return httpRegexp.test(uri) ? uri : path.join([apiUrl, uri])
}

const getTranslatedURL = (url: string, item: IArticle, lngCode: string) => {
  const gTranslatedURL = `http://translate.google.com/translate?hl=en&ie=UTF-8&u=${url}&sl=auto&tl=${lngCode}`
  switch (getArticleType(item)) {
    case ArticleTypes.Facebook: {
      const local = lngCode === 'en' ? 'US' : lngCode.toUpperCase()
      return `${url}?locale=${lngCode}_${local}`
    }
    case ArticleTypes.Instagram: {
      const local = lngCode === 'en' ? 'US' : lngCode.toUpperCase()
      return `${url}?locale=${lngCode}_${local}`
    }
    case ArticleTypes.Twitter:
      return `${url}?lang=${lngCode}`
    case ArticleTypes.TikTok:
      return `${url}?lang=${lngCode}`
    case ArticleTypes.Video: {
      if (url.includes('youtube.com/watch?v=')) {
        const newURL = url.replace('watch?v=', 'embed/')
        return `${newURL}?&hl=${lngCode}&cc_lang_pref=${lngCode}&cc_load_policy=1`
      }
      return gTranslatedURL
    }
    default: {
      return gTranslatedURL
    }
  }
}

const onPress = async (article: IArticle, lngCode: string) => {
  /**
   * user does not need translation,
   * if the preferredLanguage is not set or default,
   * or the source language is the same
   * or the link is a linkedin link
   */
  const needTranslation = !(
    (lngCode || 'default') === 'default' ||
    article.SourceLanguage === lngCode ||
    getArticleType(article) === ArticleTypes.LinkedIn
  )

  let url =
    article.VideoUrl ||
    article.FacebookArticleUrl ||
    article.LinkedInArticleUrl ||
    article.TwitterArticleUrl ||
    article.InstagramArticleUrl ||
    article.TikTokArticleUrl ||
    article.Url

  if (needTranslation) {
    url = getTranslatedURL(url, article, lngCode)
  }

  try {
    return await Linking.openURL(url)
  } catch (err) {
    return console.error('An error occurred', err)
  }
}

export const Tile: FunctionComponent<ITileProps> = (props) => {
  const { article, articleType, isUnread } = props
  const pageName = article.SourceTag.Title || 'Unknown'
  const [tempLngCode, setTempLngCode] = useState<string | undefined>()
  const { width } = useContext(ScreenSizeContext)
  const { user, setBookmarks } = useContext(UserContext)
  const index = user.BookmarkedNews.indexOf(article.ArticleId)
  const isBookmarked = index > -1

  return (
    <Pressable
      onPress={() => onPress(article, tempLngCode || user.PreferredLanguage)}
      style={({ pressed }) => ({
        opacity: pressed ? 0.5 : 1,
        flex: 1,
        width,
        maxWidth: 512,
        ...(!Browser.isMobile &&
          !Browser.isIOS && {
            paddingHorizontal: 15,
          }),
      })}
    >
      <Card
        containerStyle={[
          styles.cardContainer,
          {
            ...(isUnread && {
              borderColor: Colors.oxfordBlue,
              borderWidth: 2,
              borderTopLeftRadius: 13,
              borderTopRightRadius: 13,
            }),
          },
        ]}
      >
        <View style={styles.imageTopShadow}>
          <View
            style={[
              styles.iconWrapper,
              {
                backgroundColor: !!article.SentimentTag
                  ? Colors.oxfordBlue + 90
                  : 'transparent',
              },
            ]}
          >
            {!!article.SentimentTag ? (
              <SentimentIcon sentimentTag={article.SentimentTag} />
            ) : null}
          </View>
          <View style={styles.iconWrapper}>
            <Pressable
              onPress={() => {
                if (index === -1) {
                  setBookmarks(
                    [...user.BookmarkedNews, article.ArticleId],
                    user.BookmarkedAnalyses
                  )
                } else {
                  const tags = [...user.BookmarkedNews]
                  tags.splice(index, 1)
                  setBookmarks(tags, user.BookmarkedAnalyses)
                }
              }}
            >
              <Icon
                name={isBookmarked ? 'bookmark' : 'bookmark-border'}
                size={24}
                style={styles.favoriteIcon}
              />
            </Pressable>
          </View>
        </View>
        <Card.Image
          style={styles.image}
          containerStyle={styles.imageContainer}
          placeholderStyle={styles.imagePlaceholder}
          source={{ uri: getImageUri(article.Thumbnail) }}
          resizeMode={'cover'}
        ></Card.Image>
        {articleType === ArticleTypes.Video && (
          <View style={styles.imageShadow}>
            <View style={styles.playIconWrap}>
              <Icon
                name="play-arrow"
                color={Colors.orangeWeb}
                style={styles.playIcon}
                size={30}
              />
            </View>
          </View>
        )}
        <View style={styles.cardInfoWrapper}>
          <ArticleHeader
            article={article}
            onLanguageChange={(language) => setTempLngCode(language.Code)}
          />
          <View style={styles.sourceSentimentContainer}>
            <Text style={styles.source} numberOfLines={1}>
              <ArticleSourceIconComponent
                articleType={articleType}
                article={article}
              />
              <Text>
                {pageName} - {article.Author && `${article.Author} - `}
                {DateConverter(article.Date)}
              </Text>
            </Text>
          </View>
        </View>
      </Card>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    padding: 0,
    borderRadius: 10,
    margin: 13,
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
  },
  imageContainer: {
    width: '100%',
    height: '25vh',
    backgroundColor: '#bbbbbb',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageShadow: {
    width: '100%',
    height: '25vh',
    backgroundColor: Colors.unselectedBlue + 85,
    position: 'absolute',
    justifyContent: 'center',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  imageTopShadow: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  playIconWrap: {
    alignSelf: 'center',
    height: 50,
    width: 50,
    borderRadius: 200,
    backgroundColor: Colors.oxfordBlue + 90,
    justifyContent: 'center',
  },
  playIcon: {
    alignSelf: 'center',
  },
  cardInfoWrapper: {
    margin: 8,
  },
  sourceSentimentContainer: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    marginTop: 12,
  },
  author: {
    marginBottom: 5,
  },
  iconWrapper: {
    backgroundColor: Colors.oxfordBlue + 90,
    borderRadius: 5,
    padding: 5,
    margin: 10,
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
})
