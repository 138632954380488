import React, { FunctionComponent } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { TopTabNavigator } from './TopTabNavigator'
import { SearchScreen } from '../screens/SearchScreen'
import { FavoritesScreen } from '../screens/FavoritesScreen'

import { BottomNavigationBar } from '../components/BottomNavigationBar'

import { RootTabParamList } from '../types'
import { ListScreen } from '../screens/ListScreen'
import { HeaderComponent } from '../components/HeaderComponent'

export interface IRootNavigatorProps {}
const BottomTab = createBottomTabNavigator<RootTabParamList>()

export const RootNavigator: FunctionComponent<IRootNavigatorProps> = () => {
  return (
    <BottomTab.Navigator
      tabBar={(props) => <BottomNavigationBar {...props} />}
      screenOptions={{
        header: (props) => <HeaderComponent {...props} />,
      }}
    >
      <BottomTab.Screen
        name="Root"
        initialParams={{}}
        options={{ unmountOnBlur: true }}
      >
        {(props) => (
          <TopTabNavigator
            navigation={props.navigation}
            route={props.route as any}
          >
            <ListScreen
              navigation={props.navigation}
              route={props.route as any}
            />
          </TopTabNavigator>
        )}
      </BottomTab.Screen>
      <BottomTab.Screen name="Search" initialParams={{}}>
        {(props) => (
          <TopTabNavigator
            navigation={props.navigation}
            route={props.route as any}
          >
            <SearchScreen
              navigation={props.navigation}
              route={props.route as any}
            />
          </TopTabNavigator>
        )}
      </BottomTab.Screen>
      <BottomTab.Screen name="Favorites" component={FavoritesScreen} />
    </BottomTab.Navigator>
  )
}
